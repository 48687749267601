import { RadioGroup } from '@headlessui/react';
import { useState } from 'react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function PricingEstimationPlan({
  setSelectedPlan,
  planOptions,
  selectedPlan,
}: {
  setSelectedPlan: any;
  planOptions: any;
  selectedPlan: any;
}) {
  return (
    <RadioGroup value={selectedPlan} onChange={setSelectedPlan} className="mt-2">
      <RadioGroup.Label className="sr-only">Choose a plan</RadioGroup.Label>
      <div className="grid grid-cols-3 gap-3 sm:grid-cols-5">
        {planOptions.map((option: any) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ active, checked }) =>
              classNames(
                'cursor-pointer focus:outline-none',
                //   active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                checked
                  ? 'bg-indigo-500 text-white hover:bg-indigo-400'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1'
              )
            }
          >
            <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
